//the main scss file where every other scss will get imported

/** vendor scss */
@import "vendor/bootstrap";
@import "vendor/fotorama/fotorama";
@import "vendor/lightbox/lightbox";

@import "general/colors";
@import "general/reset";
@import "general/variables";


@import "general/general";
@import "general/header";
@import "general/footer";


@import "general/slider";
@import "general/content";
@import "general/content-menu";