.content{

  .page-header{
   border-bottom: none;
    margin: 1em 0;
  }
  &.content-side{
    .page-header{
      margin-top: 2em;
    }
  }
  img{
    margin-top: 15px;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  }


  .btn.btn-primary{
    background-color: $red;
    border-color: $red;
    color: $light-grey;
    transition: $transition-time-normal ease-in-out;
    &:hover{
      background-color: $blue;
      border-color: $blue;
    }
  }
  .padding-5{
    padding: 4px;
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .fotorama{
   display: none;
  }
}
