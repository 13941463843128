.content-menu{
  margin: 50px 0 0 0;
    ul{
      li{
        width: 33.3333%;
        display: inline-block;
        text-align: center;
        transition: $transition-time-normal ease-in-out;
        a{
          background-color: $blue;
          color: $light-grey;
          margin: 0 5px;
          transition: $transition-time-normal ease-in-out;
          &:hover{
            background-color: $red;
          }
        }
        &.active{
          a{
            background-color: $red;
          }
        }
      }

    }
}

@media only screen and (max-width: $screen-sm-max) {
  .content-menu{
    ul{
      li{
        width: 100%;
        display: block;
        margin: 5px 0px;
      }
    }
  }
}

