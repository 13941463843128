// converts rgba to a non-transparent rgb
@function rgba-to-rgb($rgba, $base: #ffffff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $base, alpha($rgba) * 100%)
}

// Add percentage of black to a color
@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

/* define various colors */
$black: #000000;
$white: #ffffff;


$light-grey: #fcfcfc;
$light-grey-hover: shade($light-grey, 20%);

$blue: #2659A3;
$blue-hover: shade($blue, 20%);

$red: #AC252A;
$red-hover: shade($red, 20%);

$light-blue: #359ed7;
$light-blue-hover: shade($light-blue, 20%);

/* define some basic usage of colors */
$main: $black;
$background:$light-grey ;




/* social media */
$facebook: #3b5998;
$twitter: #1da1f2;
$googleplus: #db4437;