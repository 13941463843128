html {
  //sticky footer hack, see https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3 or https://github.com/philipwalton/solved-by-flexbox/issues/28
  overflow-y: scroll;
  height: 100%;
}

body {
  height: 100%;
  background-color: $background;
  display: flex; //sticky footer hack
  flex-direction: column;
}

#wrapper {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  //sticky footer hack, 1 0 auto for ie11
  flex: 1 0 auto;
}
html, body {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  color: $main;
}
h1{
  font-weight: 400;
  text-align: center;
  border-bottom: 2px solid $red;
  padding-bottom: 5px;
  margin: 0;
  font-size: 2em;
}
h2{
  font-weight: 400;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.8em;
}

img{
  max-width: 100%;
}
a.tel{
  color: inherit;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
@media only screen and (max-width: $screen-sm-max) {
  html, body {
    font-size: 14px;
  }
}