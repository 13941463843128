header {
  &.topmenu {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: $light-grey;
    z-index: 100;
    position: fixed;
    width: 100%;

    .logo {
      min-height: 50px;
      background-color: $light-grey;
      img {
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        max-width: 100%;
        max-height: 80px;
        z-index: 100;
        padding: 5px;
      }
    }
    .bar{
      font-size: 1.5em;
      padding: 20px 15px;
      display: none;
    }
    > div {

    }
    nav.navbar {
      text-align: left;
      margin-bottom: 0;
      padding-top: 20px;


      ul.nav.menu {
        > li {
          display: inline-block;
          margin-right: 5px;

          > ul {
            position: absolute;
            padding-top: 15px;
            box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.3);
            display: none;
            visibility: hidden;
            transition: $transition-time-normal ease-in-out;

            > li {
              background-color: $red;
              transition: $transition-time-normal ease-in-out;

              > a {
                padding: 8px;
                color: $light-grey;
                display: block;
                width: 200px;
              }
              &.active,
              &:hover,
              &:focus {
                > a {
                  background-color: $red;
                  color: $light-grey;
                  text-decoration: none;
                }
              }

            }
          }
          a {
            color: $light-grey;
            background-color: $blue;
            transition: all $transition-time-normal ease-in-out;
            //padding-bottom: 25px;
            &:focus {
              background-color: $blue-hover;
              color: $light-grey;
            }
          }
          &.active, &:hover, &:focus {
            > a {
              background-color: $red;
              color: $light-grey;
            }
          }
          &:hover,
          &:focus {
            > ul {
              display: block;
              visibility: visible;
            }
          }

        }

      }
    }

  }
}

@media only screen and (max-width: $screen-sm-max) {

  header {

    &.topmenu {

      .bar{
        display: block;
        text-align: center;
      }
      nav.navbar {
        position: absolute;
        background-color: $light-grey;
        width: 100%;
        left: 0;
        padding: 5px;
        border-radius: 0;
        min-height: 0;
        box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.3);
        display: none;

        ul.nav.menu {
          position: relative;
          > li {
            display: block;
            margin-bottom: 1px;
            >ul{
              position: relative;
              display: block;
              padding-top: 0;
              background-color: $light-grey;
              box-shadow: none!important;
              margin-top: 1px;
              >li{
                visibility: visible;
                margin-left: 25px;
                display: block;
                margin-bottom: 1px;
                a{
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $screen-sm-max) {
  header.topmenu{
    nav.navbar {
      display: block!important;
    }
  }

}