footer{
  padding: 5px 0;
  margin-top: 50px;
  background-color: $blue;
  color: $light-grey;
  a{
    color: $light-grey;
    &:hover{
      color: $light-grey;
    }

  }
  ul.nav.menu{
    >li{
      display: inline-block;
      >a{
        display: inline-block;
        padding: 0;
        &:hover{
          text-decoration: underline;
          background-color: inherit;
          color: $light-grey;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  footer{
    text-align: center;
    ul.nav.menu{
      >li{
        display: block;
        text-align: center;
      }
    }
  }
}